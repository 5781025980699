const careerFilter = document.querySelector('#careerFilter');

if (careerFilter) {
    const careerWidget = document.querySelector('.career-widget');
    const stateList = careerWidget.querySelectorAll(".state[data-state]");
    const allCareers = careerWidget.querySelectorAll('.product-section.career');
    const careerSelect = careerWidget.querySelector('#careerFilter');
    const categorySelect = careerWidget.querySelector('#categoryFilter');

    careerSelect.addEventListener('change', (e) => {
        const categoryValue = String(categorySelect.value);
        if (categoryValue == "" || categoryValue.includes('all')) {
            const cat = categorySelect.value;
            stateList.forEach(el => {
                if (e.target.value === "all") {
                    el.style.display = "block";

                } else {
                    if (e.target.value === el.dataset.state) {
                        el.style.display = "block";
                    } else {
                        if (el.style.display = "block") {
                            el.style.display = "none";
                        }
                    }
                }
            })
            allCareers.forEach(el => {
                if (categoryValue == 'all' || el.dataset.category == categoryValue) {
                    el.style.display = 'block';
                } else {
                    el.style.display = "none";
                }
            })
        } else {
            stateList.forEach(el => {
                if (e.target.value === "all") {
                    el.style.display = "block";
                } else {
                    if (e.target.value === el.dataset.state) {
                        el.style.display = "block";
                    } else {
                        if (el.style.display == "block") {
                            el.style.display = "none";
                        }
                    }
                }

            })
        }
/*        checkStates();*/
    })

    categorySelect.addEventListener('change', (e) => {
        //const careerValue = String(careerSelect.value);
        //if (careerValue == "" || careerValue.includes('all')) {
        //    stateList.forEach(el => {
        //        el.style.display = "block";
        //    })
        //} else {
        //    const state = careerSelect.value;
        //    careerList.forEach(el => {
        //        if (e.target.value === "all") {
        //            if (el.dataset.state == state) {
        //                el.style.display = "block";
        //            } else {
        //                el.style.display = "none";
        //            }

        //        } else {
        //            if (e.target.value === el.dataset.category && state == el.dataset.state) {
        //                el.style.display = "block";
        //            } else {
        //                if (el.style.display = "block") {
        //                    el.style.display = "none";
        //                }
        //            }
        //        }

        //    })
        //}
        allCareers.forEach(el => {
            if (e.target.value == "all" || e.target.value == el.dataset.category) {
                el.style.display = "block"
            } else {
                el.style.display = "none";
            }
        })
        checkStates();
    })
    function checkStates() {
        const stateList = document.querySelectorAll(".state[data-state]");

        stateList.forEach(el => {
            const careers = el.querySelectorAll('.career');
            let hasVisibleElements = false;
            careers.forEach(car => {
                if (car.style.display == "block") {
                    hasVisibleElements = true;
                }
            })
            if (hasVisibleElements) {
                el.style.display = "block"
            } else {
                el.style.display = "none"
            }
        })
    }
}