var body = document.querySelector("body");

/* Enable Transitions */
document.addEventListener(
	"DOMContentLoaded",
	function () {
		setTimeout(function () {
			document.body.classList.remove("no-transitions");
		}, 200);
	},
	false
);

//show the alert bell on load if hidden alerts are present
document.addEventListener("DOMContentLoaded", () => {
	const alertBell = document.querySelector(".js-alert-bell");
	if (alertBell) {
		const hiddenAlerts = document.querySelectorAll(".alert.hidden-alert");
		if (hiddenAlerts.length > 0) {
			alertBell.disabled = false;
			alertBell.style.opacity = 1;
		} else {
			alertBell.disabled = true;
			alertBell.style.opacity = 0;
		}
	}
	const popupAlerts = document.querySelectorAll(".alert.ab-popup:not(.hidden-alert)");
	if (popupAlerts.length > 0) {
		body.classList.add("popup-open");
	}
});

document.addEventListener("DOMContentLoaded", () => {
	const featureContainer = document.querySelectorAll(".features-split .feature-container");
	featureContainer.forEach((el) => {
		const numItems = el.querySelectorAll("li").length;

		if (numItems == 2) {
			el.setAttribute("style", "--numItems: 1; --showItems: 1;");
		} else {
			el.setAttribute("style", `--numItems: ${numItems};--showItems: 1;`);
		}
	});
});

/* Dropdown Menu */
var dropItems = document.querySelectorAll(".main-nav > ul > li");
var mainNav = document.querySelector(".main-nav");
var navButtons = document.querySelectorAll(".header .nav-drop");

/*var wave = document.querySelector(".feature-wave");*/

var instance = new SV.HoverIntent(dropItems, {
	onEnter: function (targetItem) {
		if (window.innerWidth > 980) {
			targetItem.classList.add("active");

			const numColumns = targetItem.querySelectorAll(".column").length || "2";

			targetItem.parentNode.setAttribute("style", `--numColumns: ${numColumns}`);

			body.classList.remove("nav-closed");
			body.classList.add("nav-open");

			const left = targetItem.offsetLeft + targetItem.offsetWidth / 2 - 23;
/*			document.documentElement.style.setProperty("--navBeforeLeft", left + "px");*/

			if (body.classList.contains("search-open")) {
				body.classList.remove("search-open");
			}
			if (body.classList.contains("login-open")) {
				body.classList.remove("login-open");
			}
		}
	},
	onExit: function (targetItem) {
		mainNav.removeAttribute("data-spot");
		targetItem.classList.remove("active");

		body.classList.add("nav-closed");
		body.classList.remove("nav-open");
	},
});

// menuHover(ditem);
//});

function indexInParent(node) {
	var children = node.parentNode.childNodes;
	var num = 0;
	for (var i = 0; i < children.length; i++) {
		if (children[i] == node) return num;
		if (children[i].nodeType == 1) num++;
	}
	return -1;
}

function menuHover(el) {
	var interval;
	var outInterval;
	var mel = el.querySelector(".dropdown-area");

	if (window.innerWidth > 980) {
		el.addEventListener("mouseleave", function (event) {
			interval = setInterval(function () {
				mel.style.display = "none";
			}, 500);
			if (outInterval) {
				clearInterval(outInterval);
			}
		});

		el.addEventListener("mouseover", function (event) {
			outInterval = setInterval(function () {
				mel.style.display = "block";
			}, 500);
			if (interval) {
				clearInterval(interval);
			}
		});
	}
}

// function searchHover() {
// 	var mel = document.querySelector(".js-search");

// 	if (window.innerWidth > 980) {
// 		mel.addEventListener("mouseleave", function (event) {
// 			document.body.classList.remove("nav-hover");
// 		});

// 		mel.addEventListener("mouseover", function (event) {
// 			document.body.classList.add("nav-hover");
// 			const left = event.target.offsetLeft + event.target.offsetWidth / 2 - 9;
// 			document.documentElement.style.setProperty("--navBeforeLeft", left + "px");

// 			if (left > window.innerWidth / 2) {
// 				document.documentElement.style.setProperty("--navBeforeColor", "#2B553F");
// 			} else {
// 				document.documentElement.style.setProperty("--navBeforeColor", "#FFF");
// 			}
// 		});
// 	}
// }
// function loginHover() {
// 	var mel = document.querySelector(".js-login");

// 	if (window.innerWidth > 980) {
// 		mel.addEventListener("mouseleave", function (event) {
// 			document.body.classList.remove("nav-hover");
// 		});

// 		mel.addEventListener("mouseover", function (event) {
// 			document.body.classList.add("nav-hover");
// 			const left = event.target.offsetLeft + event.target.offsetWidth / 2 - 5;
// 			document.documentElement.style.setProperty("--navBeforeLeft", left + "px");

// 			if (left > window.innerWidth / 2) {
// 				document.documentElement.style.setProperty("--navBeforeColor", "#2B553F");
// 			} else {
// 				document.documentElement.style.setProperty("--navBeforeColor", "#FFF");
// 			}
// 		});
// 	}
// }
// searchHover();
// loginHover();

document.addEventListener(
	"click",
	(element) => {
		if (window.innerWidth < 980) {
			if (element.target.matches(".mobile-nav .nav-header")) {
				element.preventDefault();
				element.target.parentNode.classList.toggle("active");
				if (element.target.parentNode.classList.contains("active")) {
					vSlideDown(element.target.parentNode.querySelector(".mobile-drop-content"));
				} else {
					vSlideUp(element.target.parentNode.querySelector(".mobile-drop-content"));
				}
			}
		}
		return;
	},
	true
);

/*** MAIN NAV ***/
// //hide nav on anchor link of same page click in nav
// window.addEventListener(
// 	"hashchange",
// 	function (event) {
// 		var drops = document.querySelectorAll(".nav-drop");

// 		drops.forEach(function (item) {
// 			item.style.display = "none";
// 		});

// 		if (document.querySelector(location.hash).length > 0) {
// 			//Check Tabs
// 			if (document.querySelector(location.hash).classList.contains("js-tab")) {
// 				var hs = document.querySelector(location.hash).querySelector("button").click();
// 			}
// 		}
// 		//Check rates/accordion names
// 		var rates = document.querySelectorAll(".js-accordion-button");
// 		rates.forEach(function (rate) {
// 			if (
// 				rate.innerText
// 					.toLowerCase()
// 					.replace(/[^a-z0-9 ]/gim, "")
// 					.replace(/ /g, "-") == location.hash.toLowerCase().replace("#", "")
// 			) {
// 				window.scrollTo(0, 0);
// 				rate.click();

// 				setTimeout(function () {
// 					rate.parentNode.scrollIntoView({ behavior: "smooth" });
// 				}, 1000);
// 			}
// 		});
// 	},
// 	false
// );

//Check rates/accordion names
var rates = document.querySelectorAll(".js-accordion-button");
rates.forEach(function (rate) {
	if (
		rate.innerText
			.toLowerCase()
			.replace(/[^a-z0-9 ]/gim, "")
			.replace(/ /g, "-") == location.hash.toLowerCase().replace("#", "")
	) {
		window.scrollTo(0, 0);
		rate.click();
		setTimeout(function () {
			rate.parentNode.scrollIntoView({ behavior: "smooth" });
		}, 1000);
	}
});

/**
 *  RATE TABLE MOBILE SETUP
 */

var rates = document.querySelectorAll(".rate-table table");

rates.forEach(function (rt) {
	var trs = rt.querySelectorAll("tr");
	var headers = [];
	for (var i = 0; i < trs.length; i++) {
		var tds = trs[i].querySelectorAll("td,th");
		for (var j = 0; j < tds.length; j++) {
			if (i == 0) {
				headers.push(tds[j].innerText);
			} else {
				tds[j].setAttribute("data-title", headers[j]);
			}
		}
	}
});

/*** ALERTS ***/
function getCookie(name) {
	let value = `; ${document.cookie}`;
	let parts = value.split(`; ${name}=`);
	if (parts.length === 2) {
		return parts.pop().split(";").shift();
	} else {
		return null;
	}
}
document.addEventListener(
	"click",
	(element) => {
		if (element.target.matches(".js-close-alert")) {
			const parent = getParentElementWithClass(element.target, "alert");
			if (getCookie("alerts")) {
				var cook =
					getCookie("alerts") +
					parent.getAttribute("data-alert-id") +
					"|";
			} else {
				var cook = parent.getAttribute("data-alert-id") + "|";
			}
			document.cookie = "alerts=" + cook + "; path=/; max-age=" + 60 * 60 * 24 * 14 + ";";

			
			vSlideUp(parent);
			parent.setAttribute("data-hidden", "true");
			parent.classList.add("hidden-alert");
			const alertBell = document.querySelector(".js-alert-bell");
			alertBell.disabled = false;
			alertBell.style.opacity = 1;

			if (getParentElementWithClass(element.target, "ab-popup")) {
				body.classList.remove("popup-open");
			}
		}
	},
	false
);

/*** SCRIPT ANIMATIONS ***/
let observer = new IntersectionObserver(function (entries) {
	entries.forEach(function (entry) {
		// Log if the element and if it's in the viewport
		if (entry.target.matches(".brick-split")) {
			if (entry.isIntersecting) {
				entry.target.timer = setInterval(function () {
					entry.target.style.backgroundPosition =
						120 -
						-20 * (entry.target.getBoundingClientRect().top / entry.target.offsetHeight) +
						"% 0";
				}, 30);
			} else {
				clearInterval(entry.target.timer);
			}
		}
	});
});

/*** SCROLL ANIMATIONS ***/
let scrollObserver = new IntersectionObserver(
	function (entries) {
		entries.forEach(function (entry) {
			if (entry.target.matches(".scroll")) {
				if (entry.isIntersecting) {
					entry.target.classList.add("active");

					if (entry.target.matches(".count")) {
						setTimeout(function () {
							animateCountUp(entry.target);
						}, 500);
					}
				}
			}
			if (entry.target.matches(".with-arrow")) {
				if (entry.isIntersecting) {
					entry.target.timer = setInterval(function () {
						entry.target.style.setProperty(
							"--bar-position",
							150 -
								150 *
									((window.innerHeight - entry.target.getBoundingClientRect().top) /
										(window.innerHeight + entry.target.offsetHeight)) +
								"px"
						);
					}, 30);
				} else {
					clearInterval(entry.target.timer);
				}
			}
		});
	},
	{ threshold: 0.001 }
);

let underlineObserver = new IntersectionObserver(
	function (entries) {
		entries.forEach(function (entry) {
			if (entry.target.matches("strong")) {
				if (entry.isIntersecting) {
					entry.target.classList.add("active");

					/* specific class functions can run here
                if (entry.target.matches(".count")) {
                    animateCountUp(entry.target);
                }
                */
				}
			}
		});
	},
	{ threshold: 0.001 }
);

let scrolls = document.querySelectorAll(".scroll");
let underlineAnimations = document.querySelectorAll("strong");

scrolls.forEach(function (item) {
	scrollObserver.observe(item);
});
underlineAnimations.forEach(function (item) {
	underlineObserver.observe(item);
});

//Sticky Jump Nav Observer
const jumpNav = document.querySelectorAll(".jump-list");

jumpNav.forEach((el) => {
	document.addEventListener("scroll", (e) => {
		el.classList.toggle("is-sticky", el.offsetTop <= window.scrollY);
	});
});

/*** ESCAPE KEY CLOSURES ***/
// Changed keypress, Esc event is not logged on keypress, only keyup and keydown
document.body.addEventListener("keydown", function (e) {
	if (e.key == "Escape") {
		if (body.classList.contains('search-open')) {
			closeSearch();
		}
		if (body.classList.contains('login-open')) {
			closeLogin();
		}
	}
});

/** Search ***/
function openSearch() {
	const searchBtn = document.querySelectorAll('.js-search');
	body.classList.remove("nav-closed");
	body.classList.remove("login-open");

	closeAllMenus("search");
	searchBtn.forEach(el => {
		el.classList.add('open')
	})
	body.classList.add("search-open");
	body.classList.remove("search-closed");

	var searchBox = document.querySelector(".header-search .search-inp");
	setTimeout(function () {
		searchBox.focus();
	}, 800);

	/*	if (window.innerWidget < tablet_break) {*/
	window.scrollTo({ top: 0, behavior: "smooth" });
	/*	}*/
}

function closeSearch() {
	const searchBtn = document.querySelectorAll('.js-search');
	body.classList.remove("search-open");
	body.classList.add("nav-closed");

	searchBtn.forEach(el => {
		el.classList.remove('open')
	})
}

/** Menu **/
function openMenu() {
	var btn = document.querySelector(".js-mobile-nav-area");
	document.querySelector(".js-nav-mobile").classList.add("open");
	btn.classList.add("menu-open");
	document.body.classList.add("nav-open");
	document.body.classList.add("mobile-nav-open");
	document.querySelector("body").classList.add("menu-open");
}
function closeMenu() {
	document.body.classList.remove("nav-open");
	document.body.classList.remove("mobile-nav-open");
	document.querySelector(".js-nav-mobile").classList.remove("open");
	document.querySelector("body").classList.remove("menu-open");
}

/** Login **/
function openLogin() {
	var btn = document.querySelector(".js-login-area");
	const loginBtn = document.querySelectorAll('.js-login');
	var mb_img = document.querySelector(".login-mobile img");
	var mb_txt = document.querySelector(".login-mobile span");

	loginBtn.forEach(el => {
		el.classList.add('open')
	})
	
	body.classList.remove("nav-closed");
	closeAllMenus("login");

	body.classList.add("login-open");
	body.classList.remove("login-closed");
	//body.classList.remove("search-open");

	var loginBox = document.querySelector(".login-form #userid");
	//loginBox.focus();
	btn.scrollTop = 0;

	//  mb_img.src = "/Content/Images/ico-close.svg";
	//  mb_txt.innerText = "Close";
}
function closeLogin() {
	var btn = document.querySelector(".js-login-area");
	var mb_img = document.querySelector(".login-mobile img");
	var mb_txt = document.querySelector(".login-mobile span");
	const loginBtn = document.querySelectorAll('.js-login');

	loginBtn.forEach(el => {
		el.classList.remove('open')
	})
	body.classList.add("nav-closed");
	body.classList.remove("login-open");
	body.classList.add("login-closed");
	// mb_img.src = "/Content/Images/ico-lock.svg";
	// mb_txt.innerText = "Login";
}

function closeAllMenus(menu) {
	if (menu == "nav") {
		closeLogin();
		closeSearch();
	}
	if (menu == "search") {
		closeLogin();
		closeMenu();
	}
	if (menu == "login") {
		closeSearch();
		closeMenu();
	}
}

function callback(mutationList, bodyObserver) {
	mutationList.forEach(function (mutation) {
		if (mutation.type === "attributes" && mutation.attributeName === "class") {
			const loginButton = document.querySelectorAll('.js-login');
			if (body.classList.contains("login-open")) {
				loginButton.forEach(el => {
					
					if (el.querySelector('img')) {
						//el.querySelector('img').style.opacity = 0;
						//setTimeout(() => {
							el.querySelector('img').src = "/Content/Images/login-x.svg"
						//	el.querySelector('img').style.opacity = 1;
						//}, 250)
						
					} else {
						el.innerHTML = "<span>X Close</span>";
					}
				})
				
			} else {
				loginButton.forEach(el => {
					if (el.querySelector('img')) {
/*						el.querySelector('img').style.opacity = 0;*/
/*						setTimeout(() => {*/
							el.querySelector('img').src = "/Content/Images/ico-login.svg"
						//	el.querySelector('img').style.opacity = 1;
						//}, 250)
						
					} else {
						el.innerHTML = "<span>Login</span>";
					}
				})
				
			}
		}
	});
}

const bodyObserver = new MutationObserver(callback);
bodyObserver.observe(body, { attributes: true });

/*** Search Window ***/
document.addEventListener(
	"click",
	function (e) {
		//Search Open
		if (e.target.matches(".js-search")) {
			const left = e.target.offsetLeft + e.target.offsetWidth / 2 - 9;
			document.documentElement.style.setProperty("--navBeforeLeft", left + "px");

			if (!body.classList.contains("search-open")) {
				openSearch();
			} else {
				closeSearch();
			}
		}
		//Search Close
		else if (e.target.matches(".js-search-close")) {
			closeSearch();
		}
		//Search Close
		else if (e.target.matches(".play-btn .photo")) {
			var par = e.target.parentElement.parentElement.querySelectorAll("[data-video]");
			par.forEach(function (vid) {
				if (vid.getAttribute("data-video") != "") {
					vid.click();
					return;
				}
			});
		}
		//Login Open
		else if (e.target.matches(".js-login")) {
			var btn = document.querySelector(".js-login-area");
			if (body.classList.contains("login-open")) {
				closeLogin();
			} else {
				openLogin();
				const left = e.target.offsetLeft + e.target.offsetWidth / 2 - 11;
				document.documentElement.style.setProperty("--navBeforeLeft", left + "px");
			}

			if (e.target.classList.contains("login-mobile")) {
				var sp = e.target.querySelector("span");
				if (sp.innerText.trim() == "Close") {
					sp.innerText = "Close";
					e.target.classList.add("active");
				} else {
					sp.innerText = "Login";
					e.target.classList.remove("active");
				}
			}
		}
		//Login Close
		else if (e.target.matches(".js-login-close")) {
			closeLogin();
		}
		//Mobile Dropdown/Accordion
		else if (e.target.matches(".js-mobile-dropdown-btn")) {
			var content = e.target.parentNode.querySelector(".js-mobile-dropdown-content");
			var dropButton = e.target;
			if (dropButton.classList.contains("open")) {
				dropButton.classList.remove("open");
				vSlideUp(content);
			} else {
				dropButton.classList.add("open");
				vSlideDown(content);
			}
		}
		//Login Mobile Dropdown
		else if (e.target.matches(".js-login-drop")) {
			var btn = e.target.parentNode.querySelector("ul");
			if (btn.style.display != "block") {
				vSlideDown(btn); //.style.display = "block";
				e.target.parentNode.classList.add("active");
			} else {
				vSlideUp(btn); //btn.style.display = "none";
				e.target.parentNode.classList.remove("active");
			}
		}
		//Mobile Nav Open
		else if (e.target.matches(".js-nav-mobile")) {
			closeAllMenus("nav");
			if (e.target.classList.contains("open")) {
				closeMenu();
			} else {
				openMenu();
			}
		}
		//Mobile Nav Close
		else if (e.target.matches(".js-nav-mobile-close")) {
			var btn = document.querySelector(".js-mobile-nav-area");
			btn.classList.remove("menu-open");
			document.body.classList.remove("nav-open");
			// vSlideUp(btn);
		} else if (
			e.target.matches("[data-video]") &&
			!e.target.parentNode.classList.contains("cta-buttons") &&
			e.target.parentNode.id != "ctaButtons"
		) {
			if (e.target.getAttribute("data-video") != "") {
				e.preventDefault();
				let videoTitle = "";
				let videoGUID = "";
				if (
					e.target.querySelector("span.visuallyhidden").length > 0 &&
					e.target.querySelector("span.visuallyhidden").innerText != ""
				) {
					videoTitle = e.target.querySelector("span.visuallyhidden").innerText;
				}
				var vidBuild =
					"<div class='video-modal-content'><button class='video-modal-close'>&times;<span class='visuallyhidden'> close</span></button>";
				if (e.target.getAttribute("data-video").indexOf("vimeo") != -1) {
					var vid = e.target.getAttribute("data-video").replace("https://vimeo.com/", "");
					if (vid.indexOf("/") != -1) {
						vid = vid.substring(0, vid.indexOf("/"));
					}
					vidBuild +=
						"<div style='padding: 56.25% 0 0 0; position: relative;'><iframe src='https://player.vimeo.com/video/" +
						vid +
						"?title=0&byline=0&portrait=0' alt='" +
						videoTitle +
						"' style='position:absolute;top:0;left:0;width:100%;height:100%;' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe></div><script src='https://player.vimeo.com/api/player.js'></script>";
					vidBuild +=
						"<div class='transcript-container js-accordion'><button class='transcript-button js-accordion-button' type='button' aria-expanded='false'>Video Transcript <img src='/Content/images/arrow-down.svg' width='19' height='10' alt='' /></button><div class='transcript-content js-accordion-content'><p>This video uses Vimeo's native closed captioning features to provide a transcript. If closed captioning is unavailable for this video, please <a href='/contact'>contact us</a> and we can provide you with one.</p></div></div>";
				} else if (e.target.getAttribute("data-video").indexOf("youtube") != -1) {
					var vid = e.target
						.getAttribute("data-video")
						.replace("https://www.youtube.com/watch?v=", "");
					if (vid.indexOf("/") != -1) {
						vid = vid.substring(0, vid.indexOf("/"));
					}
					vidBuild +=
						"<div style='padding: 56.25% 0 0 0; position: relative;'><iframe src='https://www.youtube.com/embed/" +
						vid +
						"' alt='" +
						videoTitle +
						"' style='position:absolute;top:0;left:0;width:100%;height:100%;' frameborder='0' allow='accelerometer; autoplay; encrypted - media; gyroscope; picture -in -picture; fullscreen' allowfullscreen></iframe></div>";
					vidBuild +=
						"<div class='transcript-container js-accordion'><button class='transcript-button js-accordion-button' type='button' aria-expanded='false'>Video Transcript <img src='/Content/images/arrow-down.svg' width='19' height='10' alt='' /></button><div class='transcript-content js-accordion-content'><p>This video uses YouTube's native closed captioning features to provide a transcript. If closed captioning is unavailable for this video, please <a href='/contact'>contact us</a> and we can provide you with one.</p></div></div>";
				} else if (e.target.getAttribute("data-video").indexOf(".mp4")) {
					videoGUID = e.target.getAttribute("data-video").split("/")[2];
					vidBuild +=
						"<video width='100%' controls autoplay><source src='" +
						e.target.getAttribute("data-video") +
						"' type='video/mp4'></video>";
					// Since the transcript is queried async, make a holder for it and we'll append the content after the async completes
					vidBuild +=
						"<div class='transcript-container js-accordion'><button class='transcript-button js-accordion-button' type='button' aria-expanded='false'>Video Transcript <img src='/Content/images/arrow-down.svg' width='19' height='10' alt='' /></button><div id='transcript-" +
						videoGUID +
						"' class='transcript-content js-accordion-content'><p>For a transcript of this video, please <a href='/contact'>contact us</a>.</p></div></div>";
				}
				vidBuild += "</div>";

				var vidModal = document.querySelector(".video-modal");
				if (vidModal !== null) {
					vidModal.parentNode.removeChild(vidModal);
				}

				var div = document.createElement("div");
				div.classList.add("video-modal");
				div.innerHTML = vidBuild;

				document.body.appendChild(div);

				if (videoGUID != "" && document.getElementById("transcript-" + videoGUID) != undefined) {
					// If it's embedded, get the transcript
					let xhr = new XMLHttpRequest();
					xhr.open("GET", "/transcript/" + videoGUID);
					xhr.send();
					xhr.onload = function () {
						if (xhr.status === 200 && xhr.responseText.replace(/\"/gm, "") != "") {
							// console.log("Transcrpt Retrieval Succeeded, Status: " + xhr.status + ".");
							document.getElementById("transcript-" + videoGUID).innerHTML =
								"<p>" +
								xhr.responseText.replace(/\"/gm, "").replace(/\\r\\n/gm, "<br />") +
								"</p>";
						} else {
							// console.log("Transcrpt Retrieval Failed, Status: " + xhr.status + ".");
						}
					};
					xhr.onerror = function () {
						// console.log("Transcript Retrieval Error: " + xhr.status + ".");
					};
				}
			}
		} else if (e.target.matches(".video-modal")) {
			e.target.parentNode.removeChild(e.target);
		} else if (e.target.matches(".video-modal-content")) {
			e.stopPropagation();
		} else if (e.target.matches(".video-modal-close")) {
			e.preventDefault();
			var vidModal = document.querySelector(".video-modal");
			if (vidModal !== null) {
				vidModal.parentNode.removeChild(vidModal);
			}
		}
		//Footer Nav
		else if (e.target.matches(".footer-nav > ul > li > a")) {
			if (window.innerWidth < tablet_break) {
				e.preventDefault();
				var btn = e.target.parentNode.querySelector("ul");
				if (btn.style.display != "block") {
					vSlideDown(btn); //.style.display = "block";
					e.target.parentNode.classList.add("active");
				} else {
					vSlideUp(btn); //btn.style.display = "none";
					e.target.parentNode.classList.remove("active");
				}
			}
		} else if (e.target.matches(".js-accordion-header")) {
			var cnt = e.target.parentNode.parentNode.parentNode.querySelector(".js-accordion-content");
			e.target.parentNode.parentNode.parentNode.classList.add("open");
			vSlideDown(cnt);
		} else if (e.target.matches(".js-accordion-close")) {
			var cnt = e.target.parentNode.parentNode.parentNode.querySelector(".js-accordion-content");
			e.target.parentNode.parentNode.parentNode.classList.remove("open");
			vSlideUp(cnt);
		} else if (e.target.matches(".js-branch-selector")) {
			closeAllMenus("branch");

			document.body.classList.add("branch-open");
		} else if (e.target.matches(".js-branch-modal-close")) {
			document.body.classList.remove("branch-open");
		} else if (e.target.matches(".js-branch-modal")) {
			//Hide Locations Modal
			if (e.target.id == "locationsModal") {
				document.body.classList.remove("branch-open");
			}
		} else if (e.target.matches(".js-subscribe")) {
			//Hide Locations Modal
			if (e.target.parentNode.classList.contains("open")) {
				e.target.parentNode.classList.remove("open");
				e.target.innerText = e.target.getAttribute("data-text");
			} else {
				e.target.parentNode.classList.add("open");
				e.target.setAttribute("data-text", e.target.innerText);
				e.target.innerText = "Close";
			}
		} else if (e.target.matches(".js-alert-bell")) {
			var alerts = document.querySelectorAll(".hidden-alert");
			document.cookie = "alerts=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
			alerts.forEach(function (alert) {
				vSlideDown(alert);
				if (alert.classList.contains("ab-popup")) {
					alert.style.display = "flex";
					body.classList.add("popup-open");
				}
			});
			e.target.disabled = true;
			e.target.style.opacity = 0;
		} else if (e.target.matches(".js-bc-video")) {
			//document.querySelector(".js-bc-video").parentNode.parentNode.parentNode.querySelector("img").click();
			e.target.parentNode.parentNode.parentNode.querySelector(".photo img").click();
		} else if (e.target.matches(".jump-lnk")) {
			const lnkText = e.target.innerText.replace(/ /g, "_");
			e.target.parentNode.parentNode.classList.remove("open");
			const btn = document.querySelectorAll("[data-jumplink='" + lnkText + "']");
			if (btn.length > 0) {
				btn[0].scrollIntoView({ behavior: "smooth" });
			}
		}
		// Comparison Chart Mobile Previous
		else if (e.target.matches(".js-table-prev")) {
			var base = e.target.parentNode.parentNode.parentNode.querySelector('.compare-table'); //$(this).parent().parent();
			const nextBtn = base.parentNode.querySelectorAll('.js-table-next');
			const prevBtn = base.parentNode.querySelectorAll('.js-table-prev');
			
			nextBtn.forEach(el => { el.classList.remove("grayed"); }) 
			base.setAttribute("data-col", Number(base.getAttribute("data-col")) - 1);
			if (base.getAttribute("data-col") == 1) {
				prevBtn.forEach(el => { el.classList.add("grayed"); })
			}
			var spot = base.querySelector("table tr");
			var ths = spot.querySelectorAll("th");
			base.querySelector(".js-rate-head").innerHTML = eq.call(
				ths,
				base.getAttribute("data-col")
			).innerHTML;
			mobileButtonDisplay(base.dataset.col);
			updateDots(base);
		}
		// Comparison Chart Mobile Next
		else if (e.target.matches(".js-table-next")) {
			var base = e.target.parentNode.parentNode.parentNode.querySelector('.compare-table'); //$(this).parent().parent();
			const nextBtn = base.parentNode.querySelectorAll('.js-table-next');
			const prevBtn = base.parentNode.querySelectorAll('.js-table-prev');

			prevBtn.forEach(el => {el.classList.remove("grayed"); })
			base.setAttribute("data-col", Number(base.getAttribute("data-col")) + 1);
			var spot = base.querySelector("table tr");
			var ths = spot.querySelectorAll("th");

			if (base.getAttribute("data-col") == ths.length - 1) {
				nextBtn.forEach(el => {el.classList.add("grayed"); }) 
			}

			base.querySelector(".js-rate-head").innerHTML = eq.call(
				ths,
				base.getAttribute("data-col")
			).innerHTML;
			mobileButtonDisplay(base.dataset.col);
			updateDots(base);
		} else if (e.target.matches(".js-accordion .js-close-btn")) {
			const parent = getParentElementWithClass(e.target, "js-accordion");
			parent.querySelector(".js-accordion-button").click();
		} else if (e.target.matches(".js-tab-header")) {
			//change tab

			var tabs = e.target.parentNode.parentNode.parentNode.querySelectorAll(".js-tab");
			e.target.parentNode.parentNode.querySelector(".js-tab-slidetrack").classList.remove("visible");
			tabs.forEach(function (tab) {
				if (tab.getAttribute("data-tab") == e.target.getAttribute("data-tab")) {
					if (tab.classList.contains('active')) {
						tab.classList.remove('active')
						getParentElementWithClass(tab, 'tabs').classList.remove('tab-open')
					} else {
						tab.classList.add('active')
						tab.querySelector('.tab-content .content').style.opacity = 1;
						getParentElementWithClass(tab, 'tabs').classList.add('tab-open');
					}
/*					tab.classList.toggle("active");*/
				} else {
					tab.querySelector('.tab-content .content').style.opacity = 0;
					setTimeout(() => {
						tab.classList.remove("active");
					},200)
					
				}
				if (tab.classList.contains("active")) {
					tab.parentNode.querySelector(".js-tab-slidetrack").classList.add("visible");
				}
			});
			var headers = e.target.parentNode.parentNode.parentNode.querySelectorAll(".js-tab-header");
			headers.forEach(function (tab) {
				if (tab.getAttribute("data-tab") == e.target.getAttribute("data-tab")) {
					tab.classList.toggle("active");
				} else {
					tab.classList.remove("active");
				}
				if (tab.classList.contains("active")) {
					const width = tab.offsetWidth / 2;
					tab.parentElement.parentElement.setAttribute(
						"style",
						`--trackLeft: ${tab.offsetLeft + width - 15}px`
					);
				}
			});
		} else if (e.target.matches(".tab-name")) {
			//change tab
			e.target.classList.toggle("open");
			var tab = e.target.parentNode.querySelector(".tab-content");

			if (tab.classList.contains("tab-open")) {
				vSlideUp(tab);
				tab.classList.remove("tab-open");
				
			} else {
				vSlideDown(tab);
				tab.classList.add("tab-open");
			}
		} else if (e.target.matches(".jump-header")) {
			//change tab
			e.target.parentNode.classList.toggle("open");
		} else if (body.classList.contains('login-open') && e.target.matches("body")) {
			closeLogin();

		} else if (body.classList.contains('search-open') && e.target.matches("body")) {
			closeSearch();

		}else if (e.target.matches(".pagination-dots .dot")) {
			const compareTable = e.target.parentNode.parentNode.querySelector(".compare-table");
			var spot = compareTable.querySelector("table tr");
			var ths = spot.querySelectorAll("th");
			compareTable.dataset.col = e.target.dataset.col;
			updateDots(compareTable);
			compareTable.querySelector(".js-table-prev").classList.remove("grayed");
			compareTable.querySelector(".js-table-next").classList.remove("grayed");
			if (compareTable.dataset.col == 1) {
				compareTable.querySelector(".js-table-prev").classList.add("grayed");
			}
			if (compareTable.dataset.col == ths.length - 1) {
				compareTable.querySelector(".js-table-next").classList.add("grayed");
			}

			compareTable.querySelector(".js-rate-head").innerHTML = eq.call(
				ths,
				compareTable.getAttribute("data-col")
			).innerHTML;
			mobileButtonDisplay(compareTable.dataset.col);
		}
	},
	false
);

/** Tabs **/
var ptabs = document.querySelectorAll(".tabs");

ptabs.forEach(function (tab) {
	var tabs = tab.querySelectorAll(".js-tab");
	//Create Tab Nav
	var tabHeader = document.createElement("div");
	tabHeader.classList.add("tabs-nav");
	var cnt = document.createElement("div");
	cnt.classList.add("content");
	tabs.forEach(function (tab, index) {
		var rand = Math.floor(Math.random() * 10000000000000);
		var btn = document.createElement("button");
		btn.classList.add("js-tab-header");
		btn.setAttribute("data-tab", rand);
		tab.setAttribute("data-tab", rand);
		btn.innerHTML = tab.querySelector(".tab-name").innerHTML;
		tab.querySelector(".tab-name").style.display = "none";
		cnt.appendChild(btn);
		if (index < tabs.length - 1) {
			const divider = document.createElement("div");
			divider.classList.add("divider");
			cnt.appendChild(divider);
		}
	});
	tabHeader.appendChild(cnt);
	const slideTrack = document.createElement("div");
	slideTrack.classList.add("js-tab-slidetrack");
	tabHeader.appendChild(slideTrack);

	if (tabs.length > 0) {
		tabs[0].parentNode.insertBefore(tabHeader, tabs[0]);
	}

	//Set first as active
	// var tabBtns = tab.querySelectorAll(".js-tab-header");
	// if (tabBtns.length > 0 && !tabs[0].classList.contains("no-auto-open")) {
	// 	tabBtns[0].classList.add("active");
	// 	tabs[0].classList.add("active");
	// }
});

//Allow setting of tab via location hash
//if (window.location.hash) {
//	var hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character
//	var tabBtns = tab.querySelectorAll(".js-tab-header");

//	tabBtns.forEach(function (topic) {
//		if (
//			topic.innerText.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "") ==
//			hash.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "")
//		) {
//			topic.click();
//		}
//	});
//}

/* Anchor Links Chrome Fix */
(function (document, history, location) {
	var HISTORY_SUPPORT = !!(history && history.pushState);

	var anchorScrolls = {
		ANCHOR_REGEX: /^#[^ ]+$/,
		OFFSET_HEIGHT_PX: (window.outerWidth > 900) ? 150 : 90,

		/**
		 * Establish events, and fix initial scroll position if a hash is provided.
		 */
		init: function () {
			this.scrollToCurrent();
			window.addEventListener("hashchange", this.scrollToCurrent.bind(this));
			document.body.addEventListener("click", this.delegateAnchors.bind(this));
		},

		/**
		 * Return the offset amount to deduct from the normal scroll position.
		 * Modify as appropriate to allow for dynamic calculations
		 */
		getFixedOffset: function () {
			return this.OFFSET_HEIGHT_PX;
		},

		/**
		 * If the provided href is an anchor which resolves to an element on the
		 * page, scroll to it.
		 * @param  {String} href
		 * @return {Boolean} - Was the href an anchor.
		 */
		scrollIfAnchor: function (href, pushToHistory) {
			var match, rect, anchorOffset;

			if (!this.ANCHOR_REGEX.test(href)) {
				return false;
			}

			match = document.getElementById(href.slice(1));

			if (match) {
				rect = match.getBoundingClientRect();
				anchorOffset = window.pageYOffset + rect.top - this.getFixedOffset();
				window.scrollTo({
					top: anchorOffset,
					left: window.pageXOffset,
					behavior: "smooth",
				});

				// Add the state to history as-per normal anchor links
				if (HISTORY_SUPPORT && pushToHistory) {
					history.pushState({}, document.title, location.pathname + href);
				}
			}

			return !!match;
		},

		/**
		 * Attempt to scroll to the current location's hash.
		 */
		scrollToCurrent: function () {
			this.scrollIfAnchor(window.location.hash);
		},

		/**
		 * If the click event's target was an anchor, fix the scroll position.
		 */
		delegateAnchors: function (e) {
			var elem = e.target;

			if (elem.nodeName === "A" && this.scrollIfAnchor(elem.getAttribute("href"), true)) {
				e.preventDefault();
			}
		},
	};

	window.addEventListener("DOMContentLoaded", anchorScrolls.init.bind(anchorScrolls));
})(window.document, window.history, window.location);

//Compare Chart Highlight
var cmp = document.querySelectorAll(".compare-table");

if (cmp.length > 0) {
	var pageTitle = document.querySelector("h1");
	if (pageTitle != null) {
		cmp.forEach(function (table) {
			var ths = table.querySelectorAll("th");
			ths.forEach(function (th) {
				if (th.innerText == pageTitle.innerText) {
					var cellIndex = th.cellIndex;

					var tds = table.querySelectorAll("th,td");
					tds.forEach(function (td) {
						if (td.cellIndex == cellIndex) {
							td.classList.add("active");
						}
					});
				}
			});
		});
	}
}

/**
 * ADA - Remove id=pasted
 */
var ids = document.querySelectorAll("#isPasted");
ids.forEach(function (item) {
	item.removeAttribute("id");
});

/** Comparison Charts
 * Add controls on mobile **/
function eq(index) {
	if (index >= 0 && index < this.length) return this[index];
	else return -1;
}

/******* COUNTER ON SCROLL **********/
// How long you want the animation to take, in ms
const animationDuration = 2000;
// Calculate how long each �frame� should last if we want to update the animation 60 times per second
const frameDuration = 1000 / 60;
// Use that to calculate how many frames we need to complete the animation
const totalFrames = Math.round(animationDuration / frameDuration);
// An ease-out function that slows the count as it progresses
const easeOutQuad = (t) => t * (2 - t);

// The animation function, which takes an Element
const animateCountUp = (el) => {
	let frame = 0;
	let money = "";
	if (el.innerHTML.indexOf("$") != -1) {
		money = "$";
	}
	console.log("Count to: " + el.innerHTML.replace(/\D/g, ""));
	const countTo = parseInt(el.innerHTML.replace(/\D/g, ""), 10);
	// Start the animation running 60 times per second
	const counter = setInterval(() => {
		frame++;
		// Calculate our progress as a value between 0 and 1
		// Pass that value to our easing function to get our
		// progress on a curve
		const progress = easeOutQuad(frame / totalFrames);
		// Use the progress value to calculate the current count
		const currentCount = Math.round(countTo * progress);

		// If the current count has changed, update the element
		if (parseInt(el.innerHTML.replace(",", ""), 10) !== currentCount) {
			el.innerHTML = money + currentCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		}

		// If we�ve reached our last frame, stop the animation
		if (frame === totalFrames) {
			clearInterval(counter);
		}
	}, frameDuration);
};

/** JUMP NAVS ***/
var jumpObserver = new IntersectionObserver(
	function (entries) {
		entries.forEach(function (entry) {
			if (entry.target.matches("[data-jumplink]")) {
				if (entry.isIntersecting) {
					var jumps = document.querySelectorAll(".js-links .jump-lnk");
					jumps.forEach(function (jp) {
						if (jp.innerText == entry.target.getAttribute("data-jumplink").replace(/_/g, " ")) {
							jp.classList.add("active");
						} else {
							jp.classList.remove("active");
						}
					});
				}
			}
		});
	},
	{ rootMargin: "-50px 0px 0px 0px", threshold: [0.00001] }
);
// The element to observe
let jumps = document.querySelectorAll("[data-jumplink]");
// Attach it to the observer
jumps.forEach(function (item) {
	jumpObserver.observe(item);
});

if (document.querySelectorAll(".js-calc").length > 0) {
	document.addEventListener(
		"click",
		function (e) {
			if (e.target.matches(".js-calc")) {
				var sc = document.createElement("script");
				sc.src =
					"https://www.calcxml.com/scripts/loadCalc.js?calcTarget=" +
					e.target.getAttribute("data-calcid") +
					"&skn=736&embed=2&tabs=1&secure=1";
				document.body.appendChild(sc);

				document.querySelectorAll(".js-calc.active").forEach(function (item) {
					item.classList.remove("active");
				});
				e.target.classList.add("active");
			}
		},
		false
	);

	document.querySelector(".js-calc").click();
}

//Horizontal filter scrolling
if (document.querySelector(".js-horizontalScroll") != null) {
	for (el of document.querySelectorAll(".js-horizontalScroll")) {
		if (!el.getAttribute("style")) {
			el.setAttribute("style", "--beforeElementOpacity: 0; --afterElementOpacity: 1");
		}
		el.addEventListener("scroll", (e) => {
			if (e.target.scrollLeft == 0) {
				e.target.setAttribute("style", "--beforeElementOpacity: 0; --afterElementOpacity: 1");
				//document.documentElement.style.setProperty('--beforeElementOpacity', '1');
			} else if (e.target.scrollLeft + e.target.offsetWidth == e.target.scrollWidth) {
				e.target.setAttribute("style", "--afterElementOpacity: 0; --beforeElementOpacity: 1;");
				//document.documentElement.style.setProperty('--afterElementOpacity', '0');
			} else {
				e.target.setAttribute("style", "--afterElementOpacity: 1; --beforeElementOpacity: 1;");
				//document.documentElement.style.setProperty('--afterElementOpacity', '1');
			}
		});
	}
}

// //Scrolling Nav logic

// var lastScrollTop = 100;

// window.addEventListener(
// 	"scroll",
// 	function () {
// 		// or window.addEventListener("scroll"....
// 		if (!document.querySelector("hydrated")) {
// 			var st = window.pageYOffset || document.documentElement.scrollTop; //
// 			const headerDiv = document.querySelector(".headers");
// 			if (st > lastScrollTop) {
// 				headerDiv.classList.add("scrolling");
// 				headerDiv.classList.remove("isSticky");
// 			} else if (st < lastScrollTop) {
// 				headerDiv.classList.add("isSticky");
// 			} // else was horizontal scroll
// 			lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
// 		}
// 	},
// 	false
// );

var linkHolder = document.querySelector(".jump-nav.js-links");

if (linkHolder) {
	document.addEventListener(
		"DOMContentLoaded",
		function () {
			var jumpLinks = document.querySelectorAll("[data-jumplink]");
			var linkHolder = document.querySelector(".jump-nav.js-links");

			jumpLinks.forEach(function (lnk) {
				var btn = document.createElement("button");
				btn.innerText = lnk.getAttribute("data-jumplink").replace(/_/g, " ");

				btn.classList.add("jump-lnk");
				linkHolder.append(btn);
			});
		},
		false
	);
}

var charts = document.querySelectorAll(".compare-block");

charts.forEach(function (chart) {
	var tab = chart.querySelector("table");

	var holder = chart.querySelector(".compare-table");
	var ths = tab.querySelector("tr").querySelectorAll("th");
	if (ths.length > 1) {
		holder.setAttribute("data-col", "1");
		var controls = document.createElement("div");
		controls.classList.add("table-controls");
		controls.innerHTML =
			"<button class='js-table-prev grayed'></button><span class='f-h2 js-rate-head'>" +
			eq.call(ths, 1).innerHTML +
			"</span><button class='js-table-next'></button>";

		holder.insertBefore(controls, holder.firstChild);

		if (ths.length == 2) {
			holder.querySelector('.table-controls').style.display = 'none';
			holder.querySelector('.pagination-dots').style.display = 'none';
		}
	}
});

//Compare Table CTA logic

function mobileButtonDisplay(activeColumn) {
	const compareTable = document.querySelector(".compare-table");

	if (compareTable) {
		const buttonToMove = compareTable.querySelector(
			`.table > table > tbody > tr:last-of-type td:nth-child(${Number(activeColumn) + 1})`
		);
		if (buttonToMove.innerHTML.includes('</a>')) {
			const MBD = compareTable.querySelector(".mobileButtonDisplay");
			MBD.innerHTML = buttonToMove.innerHTML;
		} else {
			const MBD = compareTable.querySelector(".mobileButtonDisplay");
			MBD.innerHTML = "";
		}
	}
}

document.addEventListener("DOMContentLoaded", () => {
	mobileButtonDisplay(1);
});

const lnkTop = document.querySelector(".js-lnk-top");

if (lnkTop) {
	lnkTop.addEventListener("click", () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	});
}

//Comparison Table Pagination Dots Extravaganza
//           --Now in Smell-o-vision!

function updateDots(base) {
	const dots = base.parentNode.querySelectorAll(".pagination-dots .dot");
	dots.forEach((dot) => {
		if (dot.dataset.col == base.dataset.col) {
			dot.classList.add("active");
		} else {
			dot.classList.remove("active");
		}
	});
}

if (charts) {
	charts.forEach((chart) => {
		const paginationDots = chart.querySelector(".pagination-dots");
		if (paginationDots) {

			const prevArrow = document.createElement('button');
			prevArrow.classList.add('js-table-prev');
			prevArrow.classList.add('grayed');

			paginationDots.appendChild(prevArrow);
			const numCols = chart.querySelector("tr").children.length - 1;

			if (numCols > 1) {
				for (let index = 0; index < numCols; index++) {
					const dot = document.createElement("button");
					dot.classList.add("dot");
					dot.dataset.col = index + 1;

					paginationDots.append(dot);
				}
			}

			const nextArrow = document.createElement('button');
			nextArrow.classList.add('js-table-next');

			paginationDots.appendChild(nextArrow);

			updateDots(paginationDots.parentNode.parentNode.querySelector(".compare-table"));
		}
	});
}

document.addEventListener("DOMContentLoaded", () => {
	mobileButtonDisplay(1);
});

//Location Search Logic

//const LocationSearchForm = document.getElementById("find-branch-form");
//if (LocationSearchForm) {
//	const searchResultsDiv = document.querySelector(".search-results");

//	LocationSearchForm.addEventListener("submit", (e) => {
//		e.preventDefault();
//		const userInput =
//			LocationSearchForm.querySelector("#addressInput").value;
//		localStorage.setItem("userInput", userInput);
//		vSlideDown(searchResultsDiv);
//	});
//}

const closeMobileNav = () => {
	if (window.innerWidth > 900) {
		if (document.body.classList.contains("mobile-nav-open")) {
			closeMenu();
		}
	}
};
window.addEventListener("resize", closeMobileNav);


//MLO Form logic - make the form contain the users email address when the button is clicked

const mloButton = document.querySelectorAll('.js-mlo-form-btn');

mloButton.forEach(btn => {
	btn.addEventListener('click', (el) => {
		el.preventDefault();
		const email = btn.dataset.email;
		const form = document.querySelector('.mlo-form');

		if (form && email) {
			form.querySelector('#field155009900').value = email;
			form.querySelector("#field155063665_1").checked = true;
		}
		form.scrollIntoView();
	})
})