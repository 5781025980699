const locationsSearch = document.querySelector(".locationsSearch");
var searchType = "branch";
var ATMLocations;

if (locationsSearch) {
	var coords;

	var script = document.createElement("script");
	script.type = "text/javascript";
	script.src =
		"https://maps.googleapis.com/maps/api/js?key=AIzaSyDRUMoCe883TGSZ2Vrllk3zRlus65ft_3s&libraries=geometry,places&callback=initialize";
	document.body.appendChild(script);

	function initialize() {
		/*        var myLatlng = new google.maps.LatLng(-34.397, 150.644);*/
		var myOptions = {
			zoom: 12,
			mapTypeId: "terrain",
		};
		var map = new google.maps.Map(document.getElementById("map_canvas"), myOptions);
		const searchButton = document.querySelector(".js-location-search-button");
		const resultsPage = document.querySelector("#locationSearchResults");
		const searchBox = document.querySelector("#locationSearchInput");
		const input = document.querySelector(".js-location-search");
		const sortButton = document.querySelectorAll(".sortButton");
		const newSearch = document.querySelector(".js-new-search");

		//Branch
		sortButton.forEach((btn) => {
			btn.addEventListener("click", (e) => {
				sortButton.forEach((el) => {
					if (el.isEqualNode(e.target)) {
						el.classList.add("active");
						searchType = el.dataset.id;
					} else {
						el.classList.remove("active");
					}
				});
				fetchAndSortDistances(searchType);
			});
		});

		input.addEventListener("click", () => {
			if (input.value.toLowerCase() == "address not found") {
				input.value = "";
			}
		});

		newSearch.addEventListener("click", () => {
			input.value = "";
			resultsPage.style.display = "none";
			searchType = "branch";
			vSlideDown(searchBox);
			input.focus();
			sortButton.forEach((el, index) => {
				if (index == 0) {
					el.classList.add("active");
				} else {
					el.classList.remove("active");
				}
			});
		});

		searchButton.addEventListener("click", (e) => {
			e.preventDefault();

			if (input.value == "") {
				return;
			}

			loadBranchAddress(encodeLocationHTML(input.value));

			/*            }       */
		});

		function encodeLocationHTML(s) {
			return s.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/"/g, "&quot;");
		}

		function loadBranchAddress(address) {
			var geocoder = new google.maps.Geocoder();
			geocoder.geocode(
				{
					address: address,
				},
				async function (results, status) {
					console.log("geocoder created");
					if (status == google.maps.GeocoderStatus.OK) {
						coords = results[0].geometry.location;
						console.log(coords);
						await setDistances(coords.lat(), coords.lng());
						await fetchAndSortDistances();

						searchBox.style.display = "none";
						document.querySelector(".searchedAddress #searchedAddress").innerText = input.value;

						/*          map.setCenter(new google.maps.LatLng(position.coords.latitude, position.coords.longitude));*/

						vSlideDown(resultsPage);

						return;
					} else {
						console.log("Address Not Found");
						input.classList.add("not-found");
						input.value = "Address Not Found";
						return false;
					}
				}
			);
		}

		function setDistances(addressLat, addressLon) {
			//Get distance from address for each branch
			document.querySelectorAll(".js-location").forEach(function (loc) {
				if (
					loc.getAttribute("data-latlon") != null &&
					loc.getAttribute("data-latlon").indexOf(",") != -1
				) {
					var sp = loc.getAttribute("data-latlon").split(",");
					lat = Number(sp[0].trim());
					lon = Number(sp[1].trim());
					dist = distance(addressLat, addressLon, lat, lon);
					loc.setAttribute("data-distance", dist);
				}
			});
		}

		function createMarkers(locations) {
			var map = new google.maps.Map(document.getElementById("map_canvas"), {
				center: coords,
				zoom: 12,
				mapTypeId: "terrain",
			});

			if (searchType != "atm") {
				locations.forEach((loc, index) => {
					if (
						loc.getAttribute("data-latlon") != null &&
						loc.getAttribute("data-latlon").indexOf(",") != -1
					) {
						var sp = loc.getAttribute("data-latlon").split(",");
						lat = Number(sp[0].trim());
						lon = Number(sp[1].trim());

						var gMarker = new google.maps.Marker({
							map,
							position: { lat: Number(lat), lng: Number(lon) },
							title: loc.children[0].innerText,
							// icon: "",
						});
					}
				});
			} else {
				locations.forEach((loc) => {
					console.log(loc.querySelector('attribute[key="Latitude"]').innerHTML);
					console.log(loc.querySelector('attribute[key="Longitude"]').innerHTML);

					new google.maps.Marker({
						position: {
							lat: Number(loc.querySelector('attribute[key="Latitude"]').innerHTML),
							lng: Number(loc.querySelector('attribute[key="Longitude"]').innerHTML),
						},
						map,
						title: loc.querySelector('attribute[key="LocationName"]').innerHTML,
						icon: "/Content/Images/Pin.svg",
					});
				});
			}
		}

		function fetchAndSortDistances(type = "branch") {
			let locations = [];
			switch (type) {
				case "atm":
					getATMLocations();
					return;
					break;
				case "loan":
					locations = document.querySelectorAll(".js-location.loan-center[data-distance]");
					break;
				default:
					locations = document.querySelectorAll(".js-location.branch[data-distance]");
			}

			const branchArray = [...locations];

			branchArray.sort(function (a, b) {
				return a.dataset.distance - b.dataset.distance;
			});

			createMarkers(branchArray);
			const resultsDiv = document.querySelector(".resultsContainer .locations");
			resultsDiv.innerHTML = "";

			if (branchArray.length > 0) {
				branchArray.forEach((branch, index) => {
					let formattedDistance = Math.floor(Number(branch.dataset.distance) * 100) / 100;
					let fAddress = branch.querySelector(".address").innerHTML.replace("/<br />/g", ", ");

					let branchResult = document.createElement("div");
					branchResult.classList.add("location");
					branchResult.innerHTML = "";

					branchResult.innerHTML += `
                    <div class="pin" style="background-image: url(/Content/Images/Pin.svg)">
                    </div>
                    <div class="info">
                        <h3 class="f-h3 location-name"> ${branch.children[0].innerText}</h3>
                        <p class="js-distance">${formattedDistance} miles away</p>
						<p class="js-address">${fAddress}</p>
                    </div>
                    <div class="actions">
						<a href="${branch.dataset.url}" class=""><span>Get Details and Hours</span></a>
					</div>`;

					resultsDiv.appendChild(branchResult);
				})
			} else {
				resultsDiv.innerHTML = '<h2 class="f-h2 no-results">No Results Found</h2>';
			};
		}

		async function getATMLocations() {
			var xhr = new XMLHttpRequest();
			xhr.open("POST", "https://mobileapi.locatorsearch.com/LocatorSearchAPI.asmx/FindLocations", true);
			xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
			xhr.onreadystatechange = function () {
				if (xhr.readyState === 4 && xhr.status === 200) {
					var xmlDocument = xhr.responseXML;

					const locations = xmlDocument.querySelectorAll("location");
					ATMLocations = locations;

					createMarkers(locations);
					const resultsDiv = document.querySelector(".resultsContainer .locations");
					resultsDiv.innerHTML = "";

					console.log(locations[0].querySelector('attribute[key="AddressLine"]'));

					locations.forEach((branch, index) => {
						/*                        let formattedDistance = Math.floor(Number(branch.dataset.distance) * 100) / 100;*/
						let fAddress = `${branch.querySelector('attribute[key="AddressLine"]').innerHTML}, ${
							branch.querySelector('attribute[key="CityName"]').innerHTML
						}, ${branch.querySelector('attribute[key="StateCode"]').innerHTML} ${
							branch.querySelector('attribute[key="PostalCode"]').innerHTML
						}`;

						let branchResult = document.createElement("div");
						branchResult.classList.add("location");
						branchResult.innerHTML = "";

						branchResult.innerHTML += `
                        <div class="pin" style="background-image: url(/Content/Images/Pin.svg)">
                        </div>
                        <div class="info">
                            <h3 class="f-h3 location-name"> ${
								branch.querySelector('attribute[key="LocationName"]').innerHTML
							}</h3>
                            <p class="js-distance">${
								branch.querySelector('attribute[key="Distance"]').innerHTML
							} miles away</p>
						    <p class="js-address">${fAddress}</p>
                        </div>
                        <div class="actions">
						    <a href="https://www.google.com/maps/search/?api=1&query=${branch
								.querySelector('attribute[key="AddressLine"]')
								.innerHTML.replace(/\s/g, "+")}%2C${
							branch.querySelector('attribute[key="PostalCode"]').innerHTML
						} " class="speedbump" target="_blank"><span class="text">Get Directions</span></a>
					    </div>`;

						resultsDiv.appendChild(branchResult);
					});
				}
			};
			xhr.send(
				`UserName=80E99DFA&Password=80E99DFA-231A-485C-91D0-8341BCDA8ED3&AddressLine=&City=&State=&PostalCode=&Country=&Latitude=${coords.lat()}&Longitude=${coords.lng()}&Type=ATMSF&Offset=25`
			);
		}
	}
}

function distance(lat1, lon1, lat2, lon2) {
	if (lat1 == lat2 && lon1 == lon2) {
		return 0;
	} else {
		var radlat1 = (Math.PI * lat1) / 180;
		var radlat2 = (Math.PI * lat2) / 180;
		var theta = lon1 - lon2;
		var radtheta = (Math.PI * theta) / 180;
		var dist =
			Math.sin(radlat1) * Math.sin(radlat2) +
			Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = (dist * 180) / Math.PI;
		dist = dist * 60 * 1.1515;
		return dist;
	}
}
