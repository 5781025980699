function RedirectWithPostingParams(params, url) {
    var form = '<form action="' + url + '" method="post" target="">';
    for (var i = 0; i < params.length; i++) {
        form = form + '<input type="hidden" id="' + params[i].id + '" name="' + params[i].name + '" value="' + params[i].value + '"/>';
    }
    form = form + '</form>';
    var postingform = $(form);
    $('body').append(postingform);
    postingform.submit();
}

$("button#btnOnlineBankingLogin").click(function (e) {
    e.preventDefault();

    var targetURL = "https://online.mypcbbank.com/auth-olb/live/v1/remote-login";
    var targetWindow = "";
    //if (loginType == "onlinebanking") {
    //    //window.location.href = targetURL;

        //if ($("#remember_me").is(':checked')) {
        //    localStorage["onlinebanking_login"] = $('input#username', $('#frmLoginOnlneBanking')).val();
        //}
        //else {
        //    localStorage.removeItem("onlinebanking_login");
        //}
        var formparam = [{
            id: "userid", name: "userid", value: $('input#username', $('#frmLoginOnlneBanking')).val()
        }, {
            id: "password", name: "password", value: $('input#password', $('#frmLoginOnlneBanking')).val()
        }, {
            id: "testcookie", name: "testcookie", value: false
        }, {
            id: "testjs", name: "testjs", value: false
        }, {
            id: "dscheck", name: "dscheck", value: 0
        }];
        RedirectWithPostingParams(formparam, 'https://online.mypcbbank.com/auth-olb/live/v1/remote-login');

        //$('input#password', $('#frmLoginOnlneBanking')).val('');
        //if (!$("#remember_me").is(':checked')) {
        //    $('input#username', $('#frmLoginOnlneBanking')).val('');
        //}

/*    } else if (loginType == "cm") {*/

    //    if ($("#remember_me").is(':checked')) {
    //        localStorage["cm_login"] = $('input#userID', $('#frmLoginOnlneBanking')).val();
    //    }
    //    else {
    //        localStorage.removeItem("cm_login");
    //    }
    //    window.location.href = "https://pacificcitybiz.cbzsecure.com/ ";
    //    RedirectWithPostingParams(formparam, 'https://pacificcitybiz.cbzsecure.com/ ');
    //}
});