//Mortgage Calculator
const mortgageCalculator = document.querySelector(".mortgage-calculator");
const autoCalculator = document.querySelector(".autoCalculator");
const earlyPayoff = document.querySelector(".early-payoff");
const refinanceCalculator = document.querySelector(".refinance-calculator");

function inputLogic(e) {
	const val = Number(e.target.value.replace(/[^0-9.]/g, ""));
	e.target.nextElementSibling.value = val;
	if (val >= e.target.nextElementSibling.min && val < e.target.nextElementSibling.max) {
		e.target.value = Number(val).toLocaleString();
		e.target.classList.remove("invalid");
		const event = new Event("change");
		e.target.nextElementSibling.dispatchEvent(event);
	} else if (val == "") {
		e.target.value = 0;
		e.target.nextElementSibling.value = 0;
	} else {
		e.target.classList.add("invalid");
	}
}

if (mortgageCalculator) {
	mortgageCalc();
	buildTabs(mortgageCalculator.parentNode.parentNode);
}

if (autoCalculator) {
	autoCalc();
	buildTabs(autoCalculator.parentNode.parentNode);
}

if (earlyPayoff) {
	earlyPayoffCalc();
}

if (refinanceCalculator) {
	refiCalc();
}

function buildTabs(calculator) {
	const tabs = calculator.querySelectorAll(".tabName");
	const calcContainer = calculator.querySelectorAll("div[data-calcname]");

	if (tabs.length > 0 && calcContainer.length > 0) {
		tabs[0].classList.add("active");
		calcContainer[0].classList.add("active");
	}

	tabs.forEach((tab) => {
		tab.addEventListener("click", (e) => {
			tabs.forEach((el) => {
				el.classList.remove("active");
			});
			e.target.classList.add("active");
			calcContainer.forEach((el) => {
				el.classList.remove("active");
				if (el.dataset.calcname == e.target.dataset.calcname) {
					el.classList.add("active");
				}
			});
		});
	});
}

//Mortgage Calculator Logic
function mortgageCalc() {
	let homePriceInput = mortgageCalculator.querySelector("#MChome-price");
	let downPaymentInput = mortgageCalculator.querySelector("#MCdown-payment");
	let interestRateInput = mortgageCalculator.querySelector("#MCinterest-rate");
	let loanTermInput = mortgageCalculator.querySelector("#MCloan-term");
	let calculateBtn = mortgageCalculator.querySelector(".js-calculate");
	let refreshBtn = mortgageCalculator.querySelector(".js-refresh");
	let calcResult = mortgageCalculator.querySelector(".calcResult");
	let termResult = mortgageCalculator.querySelector(".termResult");
	let interestRateText = mortgageCalculator.querySelector("#interest-rate-input");
	/*	let pIResult = mortgageCalculator.querySelector(".principal-interest-result");*/

	let homePrice, downPayment, interestRate, loanTerm;

	const rateFormat = new Intl.NumberFormat("en-US", {
		minimumFractionDigits: 3,
		maximumFractionDigits: 3,
	});


	homePriceInput.addEventListener("keyup", (e) => {
		var temp = Number(homePriceInput.value.replace(/[^0-9]/g, "")).toLocaleString();
		homePriceInput.value = temp;
	});

	homePriceInput.addEventListener("change", (e) => {
		homePrice = Number(e.target.value.replace(/[^0-9]/g, ""));
		if (downPaymentInput.value = "") {
			downPayment = Math.round(homePrice * 0.2);
			downPaymentInput.value = downPayment.toLocaleString();
		}
	});

	downPaymentInput.addEventListener('keyup', () => {
		var temp = Number(downPaymentInput.value.replace(/[^0-9]/g, "")).toLocaleString();
		downPaymentInput.value = temp;
	})
	homePriceInput.addEventListener("change", (e) => {
		homePrice = Number(e.target.value.replace(/[^0-9]/g, ""));
		downPayment = Math.round(homePrice * 0.2);
		downPaymentInput.value = downPayment.toLocaleString();
	});

	downPaymentInput.addEventListener("change", (e) => {
		downpayment = Number(e.target.value.replace(/[^0-9]/g, ""));
	});

	interestRateInput.addEventListener("change", (e) => {
		if (e.target.value < 0 || e.target.value > 25) {
			e.target.value = "";
			e.target.placeholder = "Please enter a number between 0 and 25";
		}
	});

	interestRateText.addEventListener("change", (e) => {
		inputLogic(e);
	});
	interestRateInput.addEventListener("input", (e) => {
		interestRateText.value = rateFormat.format(e.target.value);
	});

	calculateBtn.addEventListener("click", () => {
		downPayment = Number(downPaymentInput.value.replace(/[^0-9]/g, ""));
		if (homePrice != "" && homePrice != undefined && downPayment != "" && downPayment != undefined && interestRateInput.value != "") {
			let principal = homePrice - downPayment;
			let rate = (Number(interestRateInput.value) / 100.0 / 12.0);
			let term = loanTermInput.value * 12.0;

			let payment = (principal * rate * Math.pow(1.0 + rate, term)) / (Math.pow(1 + rate, term) - 1.0);

			calcResult.innerText = "$" + (Math.round(payment * 100) / 100).toLocaleString();
			/*			pIResult.innerText = "$" + (Math.round(payment * 100) / 100).toLocaleString();*/
			termResult.innerText = loanTermInput.value + " Year Loan";
			mortgageCalculator.querySelector(".results").classList.add("show-results");
		} else {
			mortgageCalculator.querySelector(".results").classList.add("show-results");
			calcResult.innerText = "Insufficient Data";
		}
	});
	refreshBtn.addEventListener("click", () => {
		homePriceInput.value = "";
		downPaymentInput.value = "";
		interestRateInput.value = "";
		loanTermInput.value = "1";
		mortgageCalculator.querySelector(".results").classList.remove("show-results");
	});
}

//Early Payoff Calculator

function earlyPayoffCalc() {
	const mortgageAmount = earlyPayoff.querySelector("#EPmortgage-amt");
	const stillOwe = earlyPayoff.querySelector("#EPstill-owe");
	const loanTerm = earlyPayoff.querySelector("#EPloan-term");
	const refreshBtn = earlyPayoff.querySelector(".js-refresh");
	let interestRateInput = earlyPayoff.querySelector("#EPinterest-rate");
	let interestRateText = earlyPayoff.querySelector("#EPinterest-rate-input");

	const rateFormat = new Intl.NumberFormat("en-US", {
		minimumFractionDigits: 3,
		maximumFractionDigits: 3,
	});

	mortgageAmount.addEventListener("keyup", (e) => {
		var temp = Number(mortgageAmount.value.replace(/[^0-9]/g, "")).toLocaleString();
		mortgageAmount.value = temp;
	});

	stillOwe.addEventListener("keyup", (e) => {
		var temp = Number(stillOwe.value.replace(/[^0-9]/g, "")).toLocaleString();
		stillOwe.value = temp;
	});

	interestRateText.addEventListener("change", (e) => {
		inputLogic(e);
	});
	interestRateInput.addEventListener("input", (e) => {
		interestRateText.value = rateFormat.format(e.target.value);
	});

	earlyPayoff.querySelector(".js-calculate").addEventListener("click", () => {
		var mamount = mortgageAmount.value.replace(/[^0-9]/g, "");
		var sowe = stillOwe.value.replace(/[^0-9]/g, "");
		var ltyears = loanTerm.value;
		var ltmonths = ltyears * 12;
		var epirate = earlyPayoff.querySelector("#EPinterest-rate").value;
		var epiratemonth = parseFloat(epirate) / 100 / 12;
		var epyears = document.getElementById("EPyears-left").value;
		var epypayoff = document.getElementById("EPyears-payoff").value;
		var termleftmonths = epypayoff * 12;
		var i = Math.pow(1 + epiratemonth, termleftmonths);
		var j = Math.pow(1 + epiratemonth, ltmonths);
		var newmonthly = (Number(sowe) * Number(epiratemonth)) / (i - 1) + Number(sowe) * Number(epiratemonth);
		var originalmonthly = (Number(mamount) * Number(epiratemonth)) / (j - 1) + Number(mamount) * Number(epiratemonth);
		var principal = newmonthly - originalmonthly;

		newmonthly = parseFloat(Math.round(newmonthly * 100) / 100).toLocaleString("en-US", {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});
		originalmonthly = parseFloat(Math.round(originalmonthly * 100) / 100).toLocaleString("en-US", {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});
		principal = parseFloat(Math.round(principal * 100) / 100).toLocaleString("en-US", {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});

		earlyPayoff.querySelector(".new-principal-interest-result").innerText = "$ " + newmonthly;
		earlyPayoff.querySelector(".principal-interest-result").innerText = "$ " + originalmonthly;
		earlyPayoff.querySelector(".principal-result").innerText = "$ " + principal;
		earlyPayoff.querySelector(".results").classList.add("show-results");
	});
	refreshBtn.addEventListener("click", () => {
		mortgageAmount.value = "";
		stillOwe.value = "";
		loanTerm.value = "";
		earlyPayoff.querySelector("#EPinterest-rate").value = "";
		earlyPayoff.querySelector("#EPyears-left").value = "";
		earlyPayoff.querySelector("#EPyears-payoff").value = "";
		earlyPayoff.querySelector(".results").classList.remove("show-results");
	});
}

//Refinance Calculator

function refiCalc() {
	const newLoanAmount = refinanceCalculator.querySelector(".RNew-loan-amount");
	const currentLoan = refinanceCalculator.querySelector("#RCurrent-balance");
	const homeValue = refinanceCalculator.querySelector("#RHome-value");
	const currentPayment = refinanceCalculator.querySelector("#Rmonthly-payment");
	const propTaxes = refinanceCalculator.querySelector("#RProperty-taxes");
/*	const interestRate = refinanceCalculator.querySelector("#RInterest-rate");*/
	const loanTerm = refinanceCalculator.querySelector("#RLoan-term");
	const calcResult = refinanceCalculator.querySelector(".calcResult");
	const newPaymentResult = refinanceCalculator.querySelector(".newPaymentResult");
	const refreshBtn = refinanceCalculator.querySelector(".js-refresh");

	let interestRateInput = refinanceCalculator.querySelector("#Rinterest-rate");
	let interestRateText = refinanceCalculator.querySelector("#Rinterest-rate-input");

	const rateFormat = new Intl.NumberFormat("en-US", {
		minimumFractionDigits: 3,
		maximumFractionDigits: 3,
	});

	interestRateText.addEventListener("change", (e) => {
		inputLogic(e);
	});
	interestRateInput.addEventListener("input", (e) => {
		interestRateText.value = rateFormat.format(e.target.value);
	});

	homeValue.addEventListener("keyup", (e) => {
		e.target.value = Number(e.target.value.replace(/[^0-9]/g, "")).toLocaleString();
	});

	currentLoan.addEventListener("keyup", (e) => {
		newLoanAmount.innerText = "$ " + Number(currentLoan.value.replace(/,/g, "")).toLocaleString();
		e.target.value = Number(e.target.value.replace(/[^0-9]/g, "")).toLocaleString();
	});

	refinanceCalculator.querySelector(".js-calculate").addEventListener("click", (e) => {
		if (newLoanAmount.innerText != "" && loanTerm != "" && interestRateInput.value != "") {
			let principal = parseFloat(newLoanAmount.innerText.replace(/[^0-9]/g, ""));
			let rate = Number(interestRateInput.value / 100 / 12);
			let term = loanTerm.value * 12;

			let payment = (principal * rate * Math.pow(1 + rate, term)) / (Math.pow(1 + rate, term) - 1);

			calcResult.innerText = parseFloat(currentPayment.value, {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});

			newPaymentResult.innerText = (
				Math.round((payment + Number(propTaxes.value / 12)) * 100) / 100
			).toLocaleString();

			refinanceCalculator.querySelector(".int-rate").innerText = interestRateInput.value;
			refinanceCalculator.querySelector(".ln-trm").innerText = loanTerm.value;

			const savingsAmt =
				parseFloat(calcResult.innerText.replace(/[^0-9.]/g, "")) -
				parseFloat(newPaymentResult.innerText.replace(/[^0-9.]/g, ""));
			if (savingsAmt > 0) {
				refinanceCalculator.querySelector(".est-payment-savings").innerText =
					"$ " + Math.round((savingsAmt * 100) / 100);
			} else {
				refinanceCalculator.querySelector(".est-payment-savings").parentElement.style.display =
					"none";
			}
		}
		refinanceCalculator.querySelector(".results").classList.add("show-results");
	});
	refreshBtn.addEventListener("click", () => {
		refinanceCalculator.querySelector(".results").classList.remove("show-results");
		refinanceCalculator.querySelectorAll("input, select").forEach((el) => {
			el.value = "";
		});
	});
}

function autoCalc() {
	//Auto Loan Calculator
	//Variables
	const amountInput = autoCalculator.querySelector("#loan-amount-input");
	const amountSlider = autoCalculator.querySelector("#loan-amount-slider");
	const termInput = autoCalculator.querySelector("#loan-term-input");
	const termSlider = autoCalculator.querySelector("#loan-term-slider");
	const rateInput = autoCalculator.querySelector("#loan-rate-input");
	const rateSlider = autoCalculator.querySelector("#loan-rate-slider");
	const paymentInput = autoCalculator.querySelector("#loan-payment-input");
	const paymentSlider = autoCalculator.querySelector("#loan-payment-slider");
	const additionalInput = autoCalculator.querySelector("#additional-payment-input");
	const additionalSlider = autoCalculator.querySelector("#additional-payment-slider");
	const refreshBtn = autoCalculator.querySelector(".js-refresh");
	const resultText = autoCalculator.querySelectorAll(".resultText");

	const allSliders = autoCalculator.querySelectorAll('input[type="range"]');

	const variableChooser = autoCalculator.querySelector("#variable-select");

	const rateFormat = new Intl.NumberFormat("en-US", {
		minimumFractionDigits: 3,
		maximumFractionDigits: 3,
	});
	const numFormat = new Intl.NumberFormat("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
		currency: "USD",
		style: "currency",
	});

	refreshBtn.addEventListener("click", () => {
		amountSlider.value = "20000";
		termSlider.value = "4";
		rateSlider.value = "4";
		paymentSlider.value = "704";
		additionalSlider.value = "0";
		resultText.style.display = "none";
		allSliders.forEach((el) => {
			el.dispatchEvent(new Event("input"));
		});
		calculateResult();
	});

	document.addEventListener("DOMContentLoaded", () => {
		calculateResult();
	});

	//Event Listeners
	amountInput.addEventListener("change", (e) => {
		inputLogic(e);
	});
	amountSlider.addEventListener("input", (e) => {
		amountInput.value = "$ " + Number(e.target.value).toLocaleString();
		amountInput.classList.remove("invalid");
	});

	termInput.addEventListener("change", (e) => {
		inputLogic(e);
	});
	termSlider.addEventListener("input", (e) => {
		termInput.value = e.target.value;
	});

	rateInput.addEventListener("change", (e) => {
		inputLogic(e);
	});
	rateSlider.addEventListener("input", (e) => {
		rateInput.value = rateFormat.format(e.target.value);
	});
	paymentInput.addEventListener("change", (e) => {
		inputLogic(e);
		e.target.value = numFormat.format(Number(e.target.value.replace(/[^0-9.]/g, "")));
	});
	paymentSlider.addEventListener("input", (e) => {
		paymentInput.value = numFormat.format(e.target.value);
	});
	additionalInput.addEventListener("change", (e) => {
		inputLogic(e);
		e.target.value = numFormat.format(Number(e.target.value.replace(/[^0-9.]/g, "")));
	});
	additionalSlider.addEventListener("input", (e) => {
		additionalInput.value = numFormat.format(e.target.value);
	});

	allSliders.forEach((el) => {
		el.addEventListener("change", () => {
			calculateResult();
		});
	});

	//functions
	document.addEventListener("DOMContentLoaded", () => {
		paymentInput.parentElement.style.display = "none";
	});

	//dropdown logic
	variableChooser.addEventListener("change", (e) => {
		if (e.target.value == "monthly-payment") {
			amountInput.parentElement.style.display = "grid";
			termInput.parentElement.style.display = "grid";
			rateInput.parentElement.style.display = "grid";
			paymentInput.parentElement.style.display = "none";
			additionalInput.parentElement.style.display = "none";
		} else if (e.target.value == "loan-amount") {
			amountInput.parentElement.style.display = "none";
			termInput.parentElement.style.display = "grid";
			rateInput.parentElement.style.display = "grid";
			paymentInput.parentElement.style.display = "grid";
			additionalInput.parentElement.style.display = "none";
		} else if (e.target.value == "interest-rate") {
			amountInput.parentElement.style.display = "grid";
			termInput.parentElement.style.display = "grid";
			rateInput.parentElement.style.display = "none";
			paymentInput.parentElement.style.display = "grid";
			additionalInput.parentElement.style.display = "none";
		} else if (e.target.value == "loan-term") {
			amountInput.parentElement.style.display = "grid";
			termInput.parentElement.style.display = "none";
			rateInput.parentElement.style.display = "grid";
			paymentInput.parentElement.style.display = "grid";
			additionalInput.parentElement.style.display = "grid";
		}
		// resultText.style.display = "none";
		calculateResult();
	});

	function calculateResult() {
		let p = Number(amountSlider.value);
		let i = Number(rateSlider.value / 1200);
		let t = Number(termSlider.value * 12);
		let mp = Number(paymentSlider.value);
		let extra = Number(additionalSlider.value);
		let totalInt = 0;
		const resultsDiv = document.querySelector(".results");
		resultsDiv.querySelectorAll("div").forEach((el) => {
			el.style.display = "none";
		});
		let labelArray = [];
		let paymentArray = [];
		let extraArray = [];
		let interestArray = [];

		if (variableChooser.value == "monthly-payment") {
			const payment = ((i + i / (Math.pow(1 + i, t) - 1)) * p).toFixed(2);
			totalInt = 0;
			let count = 0;

			clearTable();

			while (p > 0) {
				const interest = p * i;
				p += interest;
				p -= payment;
				totalInt += interest;

				labelArray.push("Payment: " + count);
				interestArray.push(Number(interest).toFixed(2));
				paymentArray.push(Number(payment).toFixed(2));
				extraArray.push(Number(extra).toFixed(2));

				count++;
				outputTable(count, interest, payment, p);
			}

			buildChart(labelArray, interestArray, paymentArray, extraArray);
			resultText.forEach((el) => {
				el.style.display = "block";
				el.innerHTML = `The monthly payment amount is <span>${numFormat.format(
					payment
				)}</span> and the total interest you will pay is <span>${numFormat.format(totalInt)}</span>`;
			});
		} else if (variableChooser.value == "loan-term") {
			let payment = mp;
			totalInt = 0;
			let totalPayments = 0;
			let count = 0;

			clearTable();
			while (p > 0) {
				const interest = p * i;
				p += interest;
				p -= payment;
				p -= extra;
				totalInt += interest;
				totalPayments++;
				count++;
				labelArray.push("Payment: " + count);
				interestArray.push(Number(interest).toFixed(2));
				paymentArray.push(Number(payment).toFixed(2));
				extraArray.push((Number(payment) + Number(extra)).toFixed(2));

				outputTable(count, interest, payment, p, extra);
			}

			buildChart(labelArray, interestArray, paymentArray, extraArray);
			resultText.forEach((el) => {
				el.style.display = "block";
				el.innerHTML = `Your Loan will be fully paid off in <span>${Number(
					totalPayments / 12
				).toFixed(2)}</span> years. The total monthly payment amount is <span>${numFormat.format(
					payment + extra
				)}</span> and the total interest you will pay is <span>${numFormat.format(totalInt)}</span>`;
			});
		} else if (variableChooser.value == "interest-rate") {
			let payment = mp;
			totalInt = 0;
			let intRate = comp_ahl_loanrate();

			totalInt = payment * t - p;
			console.log("TotalInt = " + totalInt);
			console.log("Rate - " + intRate);
			resultText.forEach((el) => {
				el.style.display = "block";
				if (intRate >= 1) {
					el.innerHTML = `Invalid Input, Interest Rate is over 100%`;
				} else {
					el.innerHTML = `The Total Interest you will pay is <span>${numFormat.format(
						totalInt
					)}</span> and the rate you will pay is <span>%${(intRate * 100).toFixed(2)}</span>`;
				}
			});
		} else if (variableChooser.value == "loan-amount") {
			let payment = mp;
			let loanAmount = comp_ahl_loanamt();

			console.log("Loan Amount = " + loanAmount);
			resultText.forEach((el) => {
				el.style.display = "block";
				el.innerHTML = `The Loan Amount will be <span>${numFormat.format(loanAmount)}</span>`;
			});
		}

		function comp_ahl_loanrate() {
			var y = 3;
			var ax = Number(amountSlider.value);
			var aB = 0;
			var aH = 0;
			var e = Number(rateSlider.max) / 100 / 2;
			if (ax > 0) {
				var aG = mp;
				var aD = e / 12;
				var aC = t;
				var aE = 0;
				for (var aA = 0; aA < y; aA++) {
					aB = aD;
					var aF = aB;
					var ay = 0;
					do {
						var az = pvp(aB, aG, aC);
						aE = az - ax;
						aF /= 2;
						if (aE > 0) {
							aB += aF;
						} else {
							if (aE < 0) {
								aB -= aF;
							} else {
								aA = 3;
								break;
							}
						}
						ay++;
					} while (ay < 25 && Math.abs(aE) > 0.5);
					aH = ay === 25 ? 1 : 0;
					aD *= 2;
				}
			}
			aB *= 12;
			return roundit(aB, 0.00001);
		}
		function comp_ahl_loanamt() {
			var ay = i;
			var aA = t;
			var aB = mp;
			var aD = pvp(ay, aB, aA);
			var aC = 0;
			do {
				var ax = aB;
				aC = aD;
				aD = pvp(ay, ax, aA);
			} while (Math.abs(aC - aD) > 0.01);
			return aD;
		}

		function clearTable() {
			const table = autoCalculator.querySelector(".amortTable table");
			table.innerHTML = `<tr>
										<th>Payment</th>
										<th>Interest</th>
										<th>Principal</th>
										<th>Extra Pmt</th>
										<th>Balance</th>
									</tr>`;
		}
		function outputTable(count, interest, payment, balance, extraPmt = 0) {
			const table = autoCalculator.querySelector(".amortTable table");
			if (balance < 0) {
				balance = 0;
			}

			const el = document.createElement("tr");
			el.innerHTML = `<td>${count}</td>
							<td>$${interest.toFixed(2)}</td>
							<td>$${(payment - interest).toFixed(2)}</td>
							<td>$${extraPmt}</td>
							<td>$${balance.toFixed(2)}</td>`;
			table.appendChild(el);
		}
		function buildChart(labelArray, interestArray, paymentArray, extraArray) {
			let isChartAlive = Chart.getChart("autoChart");
			if (isChartAlive != undefined) {
				isChartAlive.destroy();
			}
			let autoChart = new Chart(autoCalculator.querySelector("#autoChart"), {
				type: "line",
				data: {
					labels: labelArray,
					datasets: [
						{
							data: interestArray,
							fill: true,
							backgroundColor: "rgb(32, 62, 128)",
							label: "Interest",
							pointRadius: "0",
						},
						{
							data: paymentArray,
							label: "Principal",
							backgroundColor: "#00B7FF",
							fill: true,
							pointRadius: "0",
						},
						{
							data: extraArray,
							fill: true,
							backgroundColor: "#F46F64",
							label: "Extra Payment",
							pointRadius: "0",
						},
					],
				},
				options: {
					maintainAspectRatio: false,
					responsive: true,
					title: {
						display: false,
					},
					scales: {
						x: {
							grid: {
								display: false,
							},
						},
						y: {
							grid: {
								display: false,
							},
						},
					},
					plugins: {
						legend: {
							display: false,
						},
					},
				},
			});
		}
	}

	function pvp(b, g, e) {
		var d = b * 1;
		var f = e * 1;
		var c = g * 1;
		if (f <= 0) {
			return c;
		}
		if (d <= 0) {
			return c * f;
		}
		return ((1 - Math.pow(1 + d, -f)) * c) / d;
	}

	function roundit(c, b) {
		var d = c / b;
		var a = Math.round(d) * b;
		return a;
	}
}
