//Feature Icon Block Logic
const iconBlockSplide = document.querySelectorAll(".icon-block-splide");
if (iconBlockSplide) {
	iconBlockSplide.forEach(el => {
		new Splide(el, {
			mediaQuery: "min",
			type: "loop",
			perPage: 1,
			gap: "5em",
			/*		padding: {left: "150px"},*/
			autoWidth: false,
			trimSpace: true,
			perMove: 1,
			focus: "center",
			breakpoints: {
				980: {
					destroy: true,
					padding: 0,
				},
			},
		}).mount();
	})
	
}

//Community Involvement - Build by Hand

//placeholder json object

const photoCarousel = document.querySelectorAll(".photo-carousel");

const initPagination = (numArticles, container) => {
	const dotContainer = container.querySelector(".pagination-dots");
	for (let x = 0; x < numArticles; x++) {
		const dotEl = document.createElement("button");
		dotEl.classList.add("dot");
		dotEl.setAttribute("alt", "pagination-dot");
		dotEl.setAttribute("aria-label", "go to next slide");
		dotEl.dataset.slide = x;
		dotContainer.appendChild(dotEl);
	}
	dotContainer.firstChild.classList.add("active");
	container.dataset.index = 0;
};
const setButtons = (articles, container) => {
	// const activeButton = container.querySelector(".pagination .dot.active");
	// const prev = container.querySelector(".pagination .prev-arrow");
	// const next = container.querySelector(".pagination .next-arrow");
	//if (activeButton.dataset.slide == 0) {
	//	prev.disabled = true;
	//	prev.classList.add("faded");
	//	next.disabled = false;
	//	next.classList.remove("faded");
	//} else if (activeButton.dataset.slide == articles.length - 1) {
	//	next.disabled = true;
	//	next.classList.add("faded");
	//	prev.disabled = false;
	//	prev.classList.remove("faded");
	//} else {
	//	prev.disabled = false;
	//	next.disabled = false;
	//	prev.classList.remove("faded");
	//	next.classList.remove("faded");
	//}
};
const setSlide = (index, container, slides) => {
	const photo = container.querySelector(".photo");
	const caption = container.querySelector(".caption");
	const dots = container.querySelectorAll(".pagination-dots .dot");

	photo.style.opacity = 0;

	setTimeout(() => {
		photo.style.backgroundImage = `url(${slides[index].imageURL})`;
		photo.style.opacity = 1;
	}, 200);

	if (slides[index].title != "") {
		caption.style.display = "block";
		caption.innerHTML = slides[index].title;
	} else {
		caption.style.display = "none";
	}

	dots.forEach((dot) => {
		if (dot.dataset.slide == index) {
			dot.classList.add("active");
		} else {
			dot.classList.remove("active");
		}
	});

	container.dataset.index = index;
};

photoCarousel.forEach(async (carousel) => {
	document.addEventListener("DOMContentLoaded", async () => {
		let images = await JSON.parse(carousel.dataset.images);
		let titles = "";
		if (carousel.dataset.titles != "") {
			titles = await JSON.parse(carousel.dataset.titles);
		}

		const slides = [];
		for (let x = 0; x < images.length; x++) {
			slides.push({
				title: (titles[x]) ? titles[x] : "",
				imageURL: images[x],
			});
		}
		console.log(slides);

		initPagination(slides.length, carousel);
		setButtons(slides.length, carousel);
		setSlide(0, carousel, slides);

		carousel.querySelector(".pagination .next-arrow").addEventListener("click", () => {
			let currentSlide = Number(carousel.dataset.index) % slides.length;
			if (slides[currentSlide + 1]) {
				currentSlide++;
				setSlide(currentSlide, carousel, slides);
				setButtons(slides, carousel);
			} else {
				currentSlide = 0;
				setSlide(currentSlide, carousel, slides);
				setButtons(slides, carousel);
			}
			setButtons(slides);
		});
		carousel.querySelector(".pagination .prev-arrow").addEventListener("click", () => {
			let currentSlide = Number(carousel.dataset.index) % slides.length;
			if (slides[currentSlide - 1]) {
				currentSlide--;
				setSlide(currentSlide, carousel, slides);
				setButtons(slides, carousel);
			} else {
				currentSlide = slides.length - 1;
				setSlide(currentSlide, carousel, slides);
				setButtons(slides, carousel);
			}
			setButtons(slides);
		});
		carousel.querySelectorAll(".pagination .dot").forEach((dot) => {
			dot.addEventListener("click", (e) => {
				const index = Number(e.target.dataset.slide);
				console.log(index + 1);
				setSlide(index, carousel, slides);
				setButtons(slides, carousel);
			});
		});
	});
});

//Card Carousel

const cardCarousels = document.querySelectorAll(".card-carousel");

if (cardCarousels.length > 0) {
	cardCarousels.forEach(async (carousel) => {
		document.addEventListener("DOMContentLoaded", async () => {
			let cards = carousel.querySelectorAll(".card");

			const slides = [];
			for (let x = 0; x < cards.length; x++) {
				slides.push({
					title: cards[x].querySelector(".info").innerHTML,
					imageURL: cards[x]
						.querySelector(".image")
						.style.backgroundImage.match(/url\(["']?([^"']*)["']?\)/)[1],
				});
			}
			console.log(slides);

			initPagination(slides.length, carousel);
			setButtons(slides.length, carousel);
			setSlide(0, carousel, slides);

			carousel.querySelector(".pagination .next-arrow").addEventListener("click", () => {
				let currentSlide = Number(carousel.dataset.index) % slides.length;
				if (slides[currentSlide + 1]) {
					currentSlide++;
					setSlide(currentSlide, carousel, slides);
					setButtons(slides, carousel);
				} else {
					currentSlide = 0;
					setSlide(currentSlide, carousel, slides);
					setButtons(slides, carousel);
				}
				setButtons(slides);
			});
			carousel.querySelector(".pagination .prev-arrow").addEventListener("click", () => {
				let currentSlide = Number(carousel.dataset.index) % slides.length;
				if (slides[currentSlide - 1]) {
					currentSlide--;
					setSlide(currentSlide, carousel, slides);
					setButtons(slides, carousel);
				} else {
					currentSlide = slides.length - 1;
					setSlide(currentSlide, carousel, slides);
					setButtons(slides, carousel);
				}
				setButtons(slides);
			});
			carousel.querySelectorAll(".pagination .dot").forEach((dot) => {
				dot.addEventListener("click", (e) => {
					const index = Number(e.target.dataset.slide);
					console.log(index + 1);
					setSlide(index, carousel, slides);
					setButtons(slides, carousel);
				});
			});
			if (slides.length < 2) {
				carousel.querySelector('.pagination').style.display = "none";
			}
		});
	});
}
