const tour = document.querySelector('.tour')

if (tour) {

    document.querySelector("body").addEventListener('click', (e) => {
        if (document.querySelector('body').classList.contains('tour-active') && !e.classList.contains('pop-window')) {
            document.querySelector('body').classList.remove('tour-active');
            $(".showOnTour").removeClass("showOnTour");
        }
    })
    document.addEventListener("DOMContentLoaded", () => {
        function getCookie(name) {
            let value = `; ${document.cookie}`;
            let parts = value.split(`; ${name}=`);
            if (parts.length === 2) {
                return parts.pop().split(';').shift();
            }
            else {
                return null;
            }
        }
        let cook = getCookie("tourDismissed");

        if ($(window).width() > 900) {
            document.querySelector('body').classList.add('showTour');
             if (!cook) {
            $(".tour").show();
            $(".slide-start").addClass("active");
            document.cookie = "tourDismissed=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=None; Secure";
             }

        }

        var currentSlide = 0;
        function loadTourSlide(spot) {
            $(".tour-slide[data-slide='" + currentSlide + "']").fadeOut("fast", function () {
                document.querySelectorAll(".showOnTour").forEach((e) => {
                    e.classList.remove("showOnTour");
                }
                )
                $(this).removeClass("active");
                currentSlide = Number(spot);
                setPosition(currentSlide);
                $(".tour-slide[data-slide='" + currentSlide + "']").fadeIn("fast", function () {
                    $(this).addClass("active");
                    $("body").addClass("tour-active").attr("data-slide", currentSlide);
                });
            });
        }

        function setPosition(spot) {
            console.log(spot);
            document.querySelector('.headers').classList.remove('showOnTour');
            document.querySelector('.headers').classList.remove('highlightNav');
            tour.classList.remove('noBG');
            body.classList.remove('nav-open');
            document.querySelector('.headers .main-nav a[href="/personal"').parentNode.classList.remove('tour-open');
            switch (Number(spot)) {
                case 1:
                    window.scrollTo({ top: 0 });
                    document.querySelector(".headers").classList.add('showOnTour');
                    document.querySelector(".headers").classList.add('highlightNav');
                    var posX = $(".headers .main-header").offset().left + $(window)['scrollLeft']();
                    var posY = $('.headers .main-header').offset().top - $(window)['scrollTop']();
                    $(".slide-navigation").css("top", posY + "px").css("left", posX + "px");
                    break;
                case 2:
                    window.scrollTo({ top: 0});
/*                    document.querySelector('.headers .loginBtn').classList.add('showOnTour');*/
                    var posX = $(".headers .loginBtn").offset().left + $(window)['scrollLeft']();
                    var posY = $('.headers .loginBtn').offset().top - $(window)['scrollTop']();
                    $(".slide-quick-access").css("top", posY + "px").css("left", posX + "px");
                    break;
                case 3:
                    //banking center slide
                    window.scrollTo({ top: 0});
                    var posX = $(".headers .nav-search").offset().left + $(window)['scrollLeft']();
                    var posY = $('.headers .nav-search').offset().top - $(window)['scrollTop']();
                    $(".slide-search").css("top", posY + "px").css("left", posX + "px");
                    break;
                case 4:
                    //connect slide
                    window.scrollTo({ top: 0});
                    var posX = $(".headers .secondary-nav a[href$='contact']").offset().left + $(window)['scrollLeft']();
                    var posY = $(".headers .secondary-nav a[href$='contact']").offset().top - $(window)['scrollTop']();
                    $(".slide-find-us").css("top", posY + "px").css("left", posX + "px");
                    break;
                case 5:
                    //resources slide
                    window.scrollTo({ top: 0});
                    document.querySelector(".headers").classList.add('showOnTour');
                    document.querySelector('.headers .main-nav ul').setAttribute('style', '--numColumns: 4');
                    document.querySelector('.headers .main-nav a[href="/personal"').parentNode.classList.add('tour-open');
                    body.classList.add('nav-open');
                    tour.classList.add('noBG');
                    var posX = $(".headers .main-nav a[href='/loans/loan-resources']").offset().left + $(window)['scrollLeft']();
                    var posY = $(".headers .main-nav a[href='/loans/loan-resources']").offset().top - $(window)['scrollTop']();
                    $(".slide-resources").css("top", posY + "px").css("left", posX + "px");
                    break;
                case 6:
                    const resourcesSection = document.querySelector('.resources-section');
                    /*                    resourcesSection && resourcesSection.parentNode.parentNode.parentNode.classList.remove("scroll");*/
                    resourcesSection && resourcesSection.scrollIntoView()
                    resourcesSection.classList.add("showOnTour");
                    tour.classList.add('noBG')
                    /*                        document.querySelector('.resource-banner').classList.add('showOnTour')*/
                    var posX = $('.resources-section a[href="/resources/security"]').offset().left + $(window)['scrollLeft']();
                    var posY = $('.resources-section a[href="/resources/security"]').offset().top - $(window)['scrollTop']();
                    $(".slide-security").css("top", posY + "px").css("left", posX + "px");
                    break;
                case 7:
                    window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }

        $(window).on("scroll", function () {
            if (document.querySelector('body').classList.contains('tour-active')) {
                setPosition(currentSlide);
            }

        });
        $(window).on("resize", function () {
            if (document.querySelector('body').classList.contains('tour-active')) {
                setPosition(currentSlide);
            }
        });


        $(".dn-dot").on("click", function (e) {
            e.preventDefault();
            loadTourSlide($(this).attr("data-slide"));
        });

        $(".dn-left").on("click", function (e) {
            e.preventDefault();
            loadTourSlide(currentSlide - 1);
        });
        $(".dn-right").on("click", function (e) {
            e.preventDefault();
            loadTourSlide(currentSlide + 1);
        });



        $(".js-start-tour,.js-restart-tour").on("click", function (e) {
            e.preventDefault();
            loadTourSlide(1);
        });
        $(".js-close-tour,.js-skip-tour").on("click", function (e) {
            e.preventDefault();
            $(".tour").hide();
            $("body").removeClass("tour-active").removeClass('nav-open').removeAttr("data-slide", currentSlide);
        });
        $(".tour").on("click", function (e) {
            $(this).hide();
            document.querySelector('.headers').classList.remove('showOnTour');
            document.querySelector('.headers').classList.remove('highlightNav');
            $("body").removeClass("tour-active").removeClass('nav-open').removeAttr("data-slide", currentSlide);
        });

        $(".tour-slide").on("click", function (e) {
            e.stopPropagation();
        });
    })

}